/*Animations*/
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Experience Swiper */
.ExperiencesSwiper.swiper {
    display: flex;
    flex-direction: row;
    padding: 40px 0 50px;
}

.ExperiencesSwiper.swiper.showOnly2 .swiper-slide:first-child {
    justify-content: flex-end;
}

.ExperiencesSwiper.swiper.showOnly2 .swiper-slide:last-child {
    justify-content: flex-start;
}

.ExperiencesSwiper .swiper-slide {
    display: flex;
    justify-content: center;
}

.ExperiencesSwiper .swiper-button-next,
.ExperiencesSwiper .swiper-button-prev {
    color: #1e1e1e;
    width: 24px;
    height: 48px;
    top: 50%;
}

.ExperiencesSwiper .swiper-button-prev:after,
.ExperiencesSwiper .swiper-button-next:after {
    font-size: 36px;
}

@media (min-width: 576px) {
    .ExperiencesSwiper .swiper-button-prev {
        left: 0;
    }

    .ExperiencesSwiper .swiper-button-next {
        right: -10px;
        position: absolute;
    }
}

@media (min-width: 768px) {
    .ExperiencesSwiper.swiper {
        width: 90%;
    }
}

/* Experiences Details Swiper */
.ExperiencesDetailSwiper.swiper {
    width: 100%;
    height: 300px;
    border-radius: 25px;
}

.ExperiencesDetailSwiper .swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 3rem;
}

.ExperiencesDetailSwiper .swiper-slide img {
    object-fit: cover;
    position: absolute;
}

.ExperiencesDetailSwiper .swiper-button-next,
.ExperiencesDetailSwiper .swiper-button-prev {
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 0;
    position: fixed;
    animation: fadeIn 3s ease-in-out;
}

.closing .ExperiencesDetailSwiper .swiper-button-next,
.closing .ExperiencesDetailSwiper .swiper-button-prev {
    opacity: 0 !important;
    animation: fadeOut 0s ease-in-out;
}

.ExperiencesDetailSwiper .swiper-button-next::after,
.ExperiencesDetailSwiper .swiper-button-prev::after {
    font-size: 36px;
}

@media (min-width: 576px) {
    .ExperiencesDetailSwiper.swiper {
        height: 500px;
    }
}

@media (min-width: 768px) {
    .ExperiencesDetailSwiper .swiper-button-prev:after,
    .ExperiencesDetailSwiper .swiper-button-next:after {
        font-size: 46px;
    }

    .ExperiencesDetailSwiper .swiper-button-prev {
        left: 50px;
    }

    .ExperiencesDetailSwiper .swiper-button-next {
        right: 50px;
    }
}

@media (min-width: 992px) {
    .ExperiencesDetailSwiper .swiper-button-prev {
        left: 60px;
    }

    .ExperiencesDetailSwiper .swiper-button-next {
        right: 60px;
    }
}

/* Flights Fare Swiper */
.FlightsFareSwiper.swiper {
    width: 320px;
}

.FlightsFareSwiper .swiper-wrapper {
    align-items: flex-end;
}

.FlightsFareSwiper .swiper-button-prev {
    left: 0;
}
.FlightsFareSwiper .swiper-button-next {
    right: 0;
}

@media (min-width: 992px) {
    .FlightsFareSwiper.swiper {
        width: 360px;
    }
    .FlightsFareSwiper .swiper-button-prev {
        left: 2%;
    }
    .FlightsFareSwiper .swiper-button-next {
        right: 2%;
    }
    .FlightsFareSwiper .swiper-button-prev,
    .FlightsFareSwiper .swiper-button-next {
        width: 36px;
        height: 36px;
    }
}

@media (min-width: 1200px) {
    .FlightsFareSwiper.swiper {
        width: 740px;
    }

    .FlightsFareSwiper .swiper-button-prev {
        left: 10px;
    }
    .FlightsFareSwiper .swiper-button-next {
        right: 10px;
    }
}

.FlightsFareSwiper .swiper-button-prev,
.FlightsFareSwiper .swiper-button-next {
    background-color: #fcb900;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: fixed;
    color: #1e1e1e;
}
.FlightsFareSwiper .swiper-button-prev:hover,
.FlightsFareSwiper .swiper-button-next:hover {
    opacity: 0.8;
}
.FlightsFareSwiper .swiper-button-prev::after,
.FlightsFareSwiper .swiper-button-next::after {
    transform: scale(0.3);
    font-weight: bolder;
}

.FlightsFareSwiper .swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    visibility: hidden;
}

/* Destination Carousel Swiper */
.DestinationCarouselSwiper.swiper {
    width: 100%;
    height: 240px;
    overflow: visible;
}

.DestinationCarouselSwiper .swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 1rem;
    height: 100%;
}

.DestinationCarouselSwiper .swiper-slide img {
    object-fit: cover;
    position: absolute;
}

.DestinationCarouselSwiper .swiper-button-next,
.DestinationCarouselSwiper .swiper-button-prev {
    background-color: #fcb900;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    color: #1e1e1e;
    top: 62%;
}

.DestinationCarouselSwiper .swiper-button-prev:after,
.DestinationCarouselSwiper .swiper-button-next:after {
    font-size: 14px;
    font-weight: bold;
}

.DestinationCarouselSwiper .swiper-pagination {
    width: 58px;
    height: 32px;
    left: calc(50% - 23px);
    background: rgba(0, 0, 0, 0.6);
    border-radius: 24px;
    padding: 4px 12px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
}

.DestinationCarouselSwiper .swiper-button-next.swiper-button-disabled,
.DestinationCarouselSwiper .swiper-button-prev.swiper-button-disabled {
    visibility: hidden;
}

/* .DestinationCarouselSwiper .swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0.5);
    width: 12px;
    height: 12px;
}

.DestinationCarouselSwiper .swiper-pagination-bullet-active {
    background: #ffffff;
}

@media (min-width: 768px) {
    .DestinationCarouselSwiper .swiper-button-prev:after,
    .DestinationCarouselSwiper .swiper-button-next:after {
        font-size: 36px;
    }

    .DestinationCarouselSwiper .swiper-button-prev {
        left: 30px;
    }

    .DestinationCarouselSwiper .swiper-button-next {
        right: 30px;
    }
}

@media (min-width: 992px) {
    .DestinationCarouselSwiper .swiper-slide {
        padding: 3rem;
        height: 570px;
    }

    .DestinationCarouselSwiper .swiper-button-prev:after,
    .DestinationCarouselSwiper .swiper-button-next:after {
        width: 18px;
        height: 36px;
    }

    .DestinationCarouselSwiper .swiper-button-prev {
        left: 37px;
    }

    .DestinationCarouselSwiper .swiper-button-next {
        right: 37px;
    }
} */
