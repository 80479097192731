.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
    background: rgba(0,0,0,0.75) !important;
}

.swal2-popup {
    border-radius: 25px !important;
}

.btn-confirm {
    color: #322500 !important;
    border: 2px solid #FCB900 !important;
    background-color: #FCB900 !important;
    vertical-align: middle;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    width: 200px;
    padding: 15px 28px;
}

.btn-confirm:focus {
    box-shadow: none !important;
}

.btn-confirm:hover {
    box-shadow: none !important;
    border: 2px solid #FDD566 !important;
    background-color: #FDD566 !important;
}

.swal2-icon.swal2-success {
    border-color: #3F8E2F !important;
    color: #3F8E2F !important;
}

.swal2-icon.swal2-success [class^=swal2-success-line] {
    background-color: #3F8E2F !important;
}

.swal2-icon.swal2-success .swal2-success-ring {
    border: 0.25em solid #3F8E2F;
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
    background-color: #C80F0F !important;
}
